<template>
    <!-- 查看答题记录 -->
    <div>
      <AnswerRecord></AnswerRecord>
    </div>
  </template>
  
  <script>
  import AnswerRecord from '@/components/report/AnswerRecord.vue';
  export default {
    
    components: {
        AnswerRecord
    }
  }
  </script>
  
  <style lang="scss" scoped>
  
  </style>